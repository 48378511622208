import { render, staticRenderFns } from "./FormMosaicDefinitionTransaction.vue?vue&type=template&id=2411b05f&scoped=true&"
import script from "./FormMosaicDefinitionTransaction.vue?vue&type=script&lang=ts&"
export * from "./FormMosaicDefinitionTransaction.vue?vue&type=script&lang=ts&"
import style0 from "./FormMosaicDefinitionTransaction.vue?vue&type=style&index=0&id=2411b05f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2411b05f",
  null
  
)

export default component.exports