




















































// external dependencies
import { Component, Vue } from 'vue-property-decorator';

// child components
import AssetFormPageWrap from '@/views/pages/assets/AssetFormPageWrap/AssetFormPageWrap.vue';
import FormMosaicDefinitionTransaction from '@/views/forms/FormMosaicDefinitionTransaction/FormMosaicDefinitionTransaction.vue';

// @ts-ignore
@Component({
    components: { AssetFormPageWrap, FormMosaicDefinitionTransaction },
})
export default class CreateMosaicPage extends Vue {}
