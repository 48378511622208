





































































// @ts-ignore
import { FormMosaicDefinitionTransactionTs } from './FormMosaicDefinitionTransactionTs';
export default class FormMosaicDefinitionTransaction extends FormMosaicDefinitionTransactionTs {}
