











import { SupplyAmountTs } from './SupplyAmountTs';
export default class SupplyAmount extends SupplyAmountTs {}
